import React, { Fragment } from 'react';
import styled from 'styled-components';
import Plx from 'react-plx';

const parallaxMainImage = [
  {
    start: 'self',
    duration: '500px',
    properties: [
      {
        startValue: 0,
        endValue: 11,
        unit: 'vh',
        property: 'translateY',
      },
    ],
  },
];

const parallaxBlurredImage = [
  {
    start: 'self',
    duration: '500px',
    properties: [
      {
        startValue: 0,
        endValue: 11,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const Viewport = styled.section`
  width: 100%;
  height: 500px;
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 0;
`;

const Parallax = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  ${'' /* background-color: ${theme.color.mint.primary}; */}
  z-index: 1;
`;

const ParallaxMainImage = styled(Plx)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: auto;
  width: 100%;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    ${'' /* transform: scale(1.02); */}
  }
`;

const ParallaxBlurredImage = styled(Plx)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: auto;
  width: 100%;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center top;
    ${'' /* transform: scale(1.02); */}
  }
`;

export default class extends React.Component {

  render() {

    const data = this.props.data;

    return (

          <Viewport>
            <Parallax
              className="parallax"
            >
              { !data.header_image_blurred &&
                <ParallaxMainImage
                  parallaxData={ parallaxMainImage }
                >
                  <img
                    src={data.header_image_bg.url}
                    alt={data.header_image_bg.alt}
                    title={data.header_image_bg.title}
                  />
                </ParallaxMainImage>
              }
              { data.header_image_blurred &&
                <Fragment>
                  <ParallaxMainImage
                    parallaxData={ parallaxMainImage }
                  >
                    <img
                      src={data.header_image_bg.url}
                      alt={data.header_image_bg.alt}
                      title={data.header_image_bg.title}
                    />
                  </ParallaxMainImage>
                  <ParallaxBlurredImage
                    parallaxData={ parallaxBlurredImage }
                  >
                    <img
                      src={data.header_image_blurred.url}
                      alt={data.header_image_blurred.alt}
                      title={data.header_image_blurred.title}
                    />
                  </ParallaxBlurredImage>
                </Fragment>
              }
            </Parallax>
          </Viewport>

    )

  }

}
