import React from 'react'
import styled from 'styled-components'

import theme from 'theme'
import Card from 'Card'
import Typography from 'Typography'

const TextCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 400px;
  margin: auto;

  padding: 0 3rem;
  padding: ${props => props.wideText && '0'};

  @media (max-width: ${theme.device.small}) {
    width: 100%;
    max-width: 100%;
    padding: 0 1rem;
  }
`;

export default class extends React.Component {

  render() {

    const { data } = this.props
    let isWideText
    data.text_width === 'narrow' ? isWideText = false : isWideText = true

    return (

        <TextCard wideText={isWideText}>
          <Typography
            display3
            heading2
            centre
            bold
            noMargin
          >
            {data.heading}
          </Typography>
          <Typography
            centre
            dangerouslySetInnerHTML={{ __html:data.text}}
          />
        </TextCard>

    )

  }

}
