import React from 'react';
import styled from 'styled-components';
import { AliceCarousel as Carousel } from 'react-alice-carousel'
import 'static/styles/alice-carousel.css'

import Layout from 'Layout';
import Image from 'Image';

// This slide is basically a container - any styling done on internal elements
const Slide = styled.div`
  width: 100%;
  height: auto;
  justify-content: center;
  font-size: 0;
  position: relative;
`;

export default class extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      carouselData: [],
    };

  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  async componentDidMount() {

    const data = await this.props.data.slides;

    await this.setStateAsync(
      {
        carouselData: data,
      }
    );

  }

  render() {

    const { carouselData } = this.state;

    const settings = {
      autoPlay: false,
      buttonsDisabled: false,
      duration: 400,
    };

    return (

      <Layout style={{padding: '3rem 0 0 0'}} >
        <Carousel {...settings}>
          {carouselData.map((item, index) => (
            <Slide key={index}>
              <Image data={item.slide} />
            </Slide>
          ))}
        </Carousel>
      </Layout>

    );

  }

}
