import React from 'react';
import styled from 'styled-components';

import theme from 'theme';

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: ${theme.device.medium}) {
    height: 100%;
  }

`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export default class extends React.Component {

  render() {

    return (

        <VideoContainer>
          <Video {...this.props} autoPlay loop muted playsInline />
        </VideoContainer>

    );

  }

}
