import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const BaseButton = styled.button`
  border: none;
  box-shadow: none;
  background-color: transparent;
  text-align: center;
  border-radius: 120px;
  border: 1px solid ${theme.color.black};
  padding: 0.8rem 4rem;
  font-weight: bold;
  font-size: ${theme.font.display6};
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  margin: ${props => props.margin && props.margin};

  &:hover {
    color: ${theme.color.white};
    background-color: ${theme.color.black};
  }
`;

const PrimaryButton = styled(BaseButton)``;
const SecondaryButton = styled(BaseButton)`
  color: ${theme.color.white};
  background-color: ${theme.color.black};

  &:hover {
    background-color: transparent;
    color: ${theme.color.black};
  }
`;

class Button extends React.Component {

  render() {

    if (this.props.secondary) {

      return (

        <SecondaryButton {...this.props}>
          {this.props.children}
        </SecondaryButton>

      );
    } else {

      return (

        <PrimaryButton {...this.props}>
          {this.props.children}
        </PrimaryButton>

      );
    }

  }

}

export default Button;
