import React from 'react'

import BuilderCarouselPanel from 'BuilderCarouselPanel'
import BuilderTextPanel from 'BuilderTextPanel'
import BuilderImagePanel from 'BuilderImagePanel'
import BuilderGridPanel from 'BuilderGridPanel'
import BuilderParallaxPanel from 'BuilderParallaxPanel'
import BuilderColumnsPanel from 'BuilderColumnsPanel'
import BuilderAccordionPanel from 'BuilderAccordionPanel'

export default class extends React.Component {

  render() {

    const content = this.props.data.acf.content_builder

    if (
          (content === null) ||
          (content === false) ||
          (content === undefined)
        ) return null

    else {

      return (

        <React.Fragment>

          {content.map((item, i) =>
            {
              if ( item['acf_fc_layout'] === 'carousel' ) {
                return <BuilderCarouselPanel data={item} key={i}/>
              } else if ( item['acf_fc_layout'] === 'text_block' ) {
                return <BuilderTextPanel data={item} key={i}/>
              } else if ( item['acf_fc_layout'] === 'grid_content' ) {
                return <BuilderGridPanel data={item} key={i}/>
              } else if ( item['acf_fc_layout'] === 'image' ) {
                return <BuilderImagePanel data={item} key={i}/>
              } else if ( item['acf_fc_layout'] === 'parallax' ) {
                return <BuilderParallaxPanel data={item} key={i} counter={i}/>
              } else if ( item['acf_fc_layout'] === 'columns' ) {
                return <BuilderColumnsPanel data={item} key={i}/>
              } else if ( item['acf_fc_layout'] === 'accordionDISABLED' ) {
                return <BuilderAccordionPanel data={item} key={i}/>
              } else return null
            }
          )}

        </React.Fragment>

      )

    }

  }

}
