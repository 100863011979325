import React from 'react';

import getData from 'Data';

import SiteTitle from 'SiteTitle';
import Menu from 'Menu';

class Error extends React.Component {

  render() {

    document.getElementById('loading').style.display = 'none';

    const {
      data,
      dataGlobal,
    } = this.props;

    return (
      <main>
        <SiteTitle>Long Play</SiteTitle>
        <Menu data={dataGlobal.menus} transparent />
        <div data={data}/>
      </main>
    );

  }

}

export default getData('404-page')(Error);
