import React from 'react';
import styled from 'styled-components';

import theme from 'theme';

/*
Row:
- unstyled row of content, full width, no margins
- any BG image or video in RowWithBG
Container:
- centered in middle at max-width from designs
- has margin/padding within row boundary
*/


const Row = styled.section`
  background-color: ${theme.color.white};
  position: relative;
  width: 100%;
  padding: ${props => props.padding && props.padding};
`;

const Container = styled.div`
  max-width: ${theme.layout.width};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin: ${props => props.margin && props.margin};
  padding: ${props => props.padding && props.padding};
  max-width: ${props => props.narrow && theme.layout.narrow};
  background-color: transparent;

  @media (max-width: ${theme.device.medium}) {
    padding: 0 1rem;
  }

`;

class Layout extends React.Component {

  render() {

      if (this.props.container) {
        return (
          <Row {...this.props}>
            <Container {...this.props}>
              {this.props.children}
            </Container>
          </Row>
        );
      } else {
        return (
          <Row {...this.props} >
            {this.props.children}
          </Row>
        );
      }

  }

}

export { Row, Container };
export default Layout;
