import React from 'react';
import styled from 'styled-components';

import Layout from 'Layout';
import Image from 'Image';

const ImageLayout = styled(Layout)`
  font-size: 0;
`;

export default class extends React.Component {

  render() {

    const image = this.props.data.image;

    return (

      <ImageLayout>
        <Image data={image} />
      </ImageLayout>

    );

  }

}
