import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Modal from "Modal";

import theme from "theme";
import Card from "Card";
import List from "List";
import Cross from "../elements/icons/Cross";
import Menu from "../elements/icons/Menu";

const IconMenu = styled(Menu)`
  height: 50px;
  width: 50px;
  color: ${theme.color.white};
  transition: color 0.3 ease-in-out;
  display: inline-block;
  position: relative;
  right: 1rem;
  padding: 15px;

  &:hover {
    color: ${theme.color.black};
  }
`;

const IconClose = styled(Cross)`
  height: 50px;
  width: 50px;
  color: ${theme.color.white};
  transition: all 0.3 ease-in-out;
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 1rem;
  padding: 15px;

  &:hover {
    cursor: pointer;
    color: ${theme.color.black};
  }
`;

const MobileMenuList = styled(List)`
  margin: 0;
  color: ${theme.color.white};
  font-size: ${theme.font.display4};
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 3rem;
  text-transform: uppercase;
  text-align: center;

  a {
    padding-bottom: 0.2rem;

    &:hover {
      border-bottom: 2px solid ${theme.color.black};
      color: ${theme.color.black};
    }
  }
`;

const LinkButton = styled.li`
  margin-bottom: 2rem;

  a {
    color: inherit;
    font-size: ${theme.font.display4};
    font-weight: bold;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border-bottom: none;
    border-bottom-color: none;
    position: relative;
    text-align: inherit;
    border: 2px solid ${theme.color.white};
    border-radius: 6px;
    padding: 0.45rem 0.9rem 0.5rem 1rem;
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(e) {
    this.setState({ isOpen: !this.state.isOpen });
    e.preventDefault();
  }

  handleNavItemClick = (e) => {
    const targetUrl = e.target.getAttribute("href");
    const currentUrl = window.location.pathname;
    if (targetUrl === currentUrl) {
      this.setState({ isOpen: false });
      e.preventDefault();
    }
  };

  render() {
    const menu = this.props.data;

    if (
      menu === null ||
      menu === false ||
      menu === undefined ||
      (menu.hasOwnProperty("data") && menu.data.status === 404)
    )
      return null;
    else {
      return (
        <div>
          <a href="/menu" onClick={this.toggleModal} margin={"1rem 1rem 0"}>
            <IconMenu className={"MenuIcon"} />
          </a>
          <Modal
            isOpen={this.state.isOpen}
            onBackgroundClick={this.toggleModal}
            onEscapeKeydown={this.toggleModal}
          >
            <IconClose onClick={this.toggleModal} />
            <Card>
              <MobileMenuList block>
                <LinkButton>
                  <a href="https://www.opentable.com.au/restref/client/?rid=143984">
                    Make Booking
                  </a>
                </LinkButton>
                <li>
                  <Link to={"/"} onClick={this.handleNavItemClick}>
                    Home
                  </Link>
                </li>
                {menu.map((item, i) => (
                  <li key={i}>
                    <Link
                      to={"/" + item.slug}
                      onClick={this.handleNavItemClick}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </MobileMenuList>
            </Card>
          </Modal>
        </div>
      );
    }
  }
}
