export default (url, options) => {

  // Check if page has been hard reloaded and clear session
  if (window.performance) {
    if (performance.navigation.type === 1) {
      sessionStorage.clear();
    }
  }

  // Use the URL as the cache key to sessionStorage
  let cacheKey = url

  // START new cache HIT code
  let cached = sessionStorage.getItem(cacheKey)
  if (cached !== null) {
    // it was in sessionStorage! Yay!
    let response = new Response(new Blob([cached]))
    return Promise.resolve(response)
  }
  // END new cache HIT code

  // TODO introduce error checking if JSON file is missing
  // currently handled in getFetchJson
  return fetch(url, options)
    .then(response => {
      // let's only store in cache if the content-type is
      // JSON or something non-binary
      if (response.status === 200) {
        let ct = response.headers.get('Content-Type')
        if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
          // There is a .json() instead of .text() but
          // we're going to store it in sessionStorage as
          // string anyway.
          // If we don't clone the response, it will be
          // consumed by the time it's returned. This
          // way we're being un-intrusive.
          response.clone().text().then(content => {
            sessionStorage.setItem(cacheKey, content)
          })
        }
      }
      return response
    })
}
