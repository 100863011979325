import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
  width: 100%;
  height: auto;

  border-radius: ${props => props.rounded && '0.5rem'};
`;

export default class extends React.Component {

  render() {

    const { data } = this.props;

    return (

      <Img
        src={this.props.src || data.url}
        // alt={data.alt || this.props.alt}
        // title={data.title || this.props.title}
        width={this.props.width || data.width}
        height={this.props.height || data.height}
        {...this.props}
      />

    );

  }

}
