import React, { Fragment } from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion'

import 'static/styles/accordion.css'

import Layout from 'Layout';
import Typography from 'Typography';

export default class BuilderAccordionPanel extends React.Component {

  render() {

    const settings = {
      accordion: true,
    };

    const data = this.props.data.acf.content_builder[1].panel

    if ( data === ( null || false ) ) return null

    else {

      return (

        <Fragment>
          <Layout id={'careers'} container style={{padding: '0 0 1rem 0'}}>
            <Typography
              display3
              heading2
              bold
              centre
              noMargin
              dangerouslySetInnerHTML={{ __html:'Work with us'}}
            />
          </Layout>
          <Layout container narrow style={{padding: '0 0 5rem 0'}}>
            <Accordion {...settings}>
              {data.map((item, index) => (
                <AccordionItem key={index}>
                  <AccordionItemTitle>
                    <Typography
                      className="u-position-relative"
                      div
                      display5
                      heading3
                      bold
                      noMargin
                      uppercase
                      spaced
                    >
                      <div dangerouslySetInnerHTML={{ __html:item.heading}} />
                      <div className="accordion__arrow" role="presentation" />
                    </Typography>

                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <Typography
                      dangerouslySetInnerHTML={{ __html:item.content}}
                    />
                  </AccordionItemBody>
                </AccordionItem>
              ))}
            </Accordion>
          </Layout>
        </Fragment>

      )

    }

  }

}
