import React from "react";

import getFetchJson from "Response";

import SiteTitle from "SiteTitle";
import Menu from "MenuHome";
import Header from "Header";
// import HeroPanel from 'HeroPanel'
import Builder from "Builder";
import Footer from "Footer";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      dataGlobal: []
    };
  }

  getJson = () => {
    const dataGlobal = getFetchJson("home", "Global");

    return Promise.all([dataGlobal]).then(json => {
      this.setState({
        dataGlobal: json[0],
        isLoaded: true
      });
    });
  };

  componentDidMount() {
    this.getJson();
  }

  render() {
    const { error, isLoaded, dataGlobal } = this.state;

    console.log(dataGlobal);

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return null;
    } else {
      return (
        <main>
          <SiteTitle>Long Play</SiteTitle>
          <Menu data={dataGlobal.menus} transparent />
          <Header data={dataGlobal.home} />
          <Builder data={dataGlobal.home} />
          <Footer />
        </main>
      );
    }
  }
}

export default Home;
