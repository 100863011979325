import React, { Fragment } from 'react'
import styled from 'styled-components'

import theme from 'theme'
import Typography from 'Typography'
import VideoInBG from 'VideoInBG'

import HeaderParallax from 'HeaderParallax'

const HeaderLayout = styled.header`
  height: ${props => props.bgHeight && props.bgHeight};
  width: 100vw;
  position: relative;
  display: block;
  overflow: hidden;
  background-color: ${props => props.bgColor && props.bgColor};
  z-index: 2;

  @media (max-width: ${theme.device.medium}) {
    height: 60vh;
  }
`;

const Title = styled(Typography)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: ${props => props.position && props.position };
  ${'' /* padding-bottom: ${props => props.position && '30px' }; */}
  padding-bottom: 30px;
  z-index: 4;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;

  @media (max-width: ${theme.device.medium}) {
    height: 60vh;
  }
`;

const ImageInFront = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  margin: auto;
  height: 100%;
  width: auto;
  z-index: 3;
`;

const ImageInBG = styled.div`
  width: 100%;
  min-height: 100%;
  z-index: 2;

  background-image: url(${props => props.src && props.src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
    };
  }

  render() {

    const wp = this.props.data;
    const key = this.props.data.id

    let hasBG = false,
        hasTitle = false,
        isVideoBG = false,
        isParallaxBG = false,
        bgColor = null,
        bgHeight = '80vh',
        textColor = theme.color.black,
        textPosition = 'center'

    if ( (wp.acf.header_background !== 'none') || (wp.acf.header_background !== 'colour') ) {
      hasBG = true
      if (wp.acf.header_background === 'video') isVideoBG = true
      else if (wp.acf.header_parallax === 'on') isParallaxBG = true
    }

    if ( (wp.acf.header_show_title === 'yes') ) hasTitle = true

    switch ( wp.acf.header_bg_colour ) {
      case 'mint':
        bgColor = theme.color.mint.lightest
      break
      case 'beige':
        bgColor = theme.color.beige.primary
      break
      case 'black':
        bgColor = theme.color.black
      break
      default:
        bgColor = theme.color.white;
    }

    switch ( wp.acf.header_text_colour ) {
      case 'mint':
        textColor = theme.color.mint.lightest
      break
      case 'beige':
        textColor = theme.color.beige.primary
      break
      case 'white':
        textColor = theme.color.white
      break
      default:
        textColor = theme.color.black;
    }

    if (wp.acf.header_height === 'small') {
      textPosition = 'flex-end'
      bgHeight = '200px'
    }

    return (

      <HeaderLayout
        key={key}
        bgColor={bgColor}
        bgHeight={bgHeight}
      >
        { hasBG
          ?
            ( isVideoBG
              ?
              <VideoInBG src={wp.acf.header_video} />
              :
              <ImageContainer>
                { isParallaxBG
                  ?
                  <Fragment>
                    <HeaderParallax
                      data={wp.acf}
                    />
                  </Fragment>
                  :
                  <Fragment>
                    { wp.acf.header_image_bg &&
                      <ImageInBG
                        src={wp.acf.header_image_bg.url}
                      />
                    }
                    { wp.acf.header_image_front &&
                      <ImageInFront
                        src={wp.acf.header_image_front.url}
                        alt={wp.acf.header_image_front.alt}
                        title={wp.acf.header_image_front.title}
                        width={wp.acf.header_image_front.width}
                        height={wp.acf.header_image_front.height}
                      />
                    }
                  </Fragment>
                }
              </ImageContainer>
            )
          : null
        }
        { hasTitle &&
          <Title
            heading1
            display2
            bold
            color={textColor}
            position={textPosition}
          >
            {
              wp.title
              ?
              wp.title
              :
              'Error'
            }
          </Title>
        }
      </HeaderLayout>

    );

  }

}
