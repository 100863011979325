import React from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
`;

// Inline list by default
const Inline = styled(Ul)`
  li {
    display: inline;
  }
`;

const Block = styled(Ul)`
  display: block;
`;

const Flex = styled(Ul)`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;

class Item extends React.Component {

  render() {

    return (

      <li>{this.props.children}</li>

    );

  }
}

class List extends React.Component {

  render() {

    if (this.props.block) {

      return (

        <Block {...this.props}>
          {this.props.children}
        </Block>

      );


    } else if (this.props.flex) {

      return (

        <Flex {...this.props}>
          {this.props.children}
        </Flex>

      );

    } else {

      return (

        <Inline {...this.props}>
          {this.props.children}
        </Inline>

      );

    }
  }

}

export { List, Item };
export default List;
