import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  position: relative;

  position: ${props => props.absolute && 'absolute'};
  top: ${props => props.absolute && '0'};
  left: ${props => props.absolute && '0'};
  right: ${props => props.absolute && '0'};
  width: ${props => props.absolute && '100%'};
  height: ${props => props.absolute && '100%'};

  margin: ${props => props.margin && props.margin};

  display: ${props => props.flex && 'flex'};

`;

export default class extends React.Component {

  render() {

    return (

      <Card {...this.props}>
        {this.props.children}
      </Card>

    );

  }

}
