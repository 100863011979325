import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ScrollVisibilityHeader from "ScrollVisibilityHeader";

import theme from "theme";

// import NavMenuList from "NavMegaMenuList";
// import NavMenuList from "NavMenuList";
import MenuInModal from "MenuInModal";
import Image from "Image";
import Typography from "Typography";

import logo from "static/images/logo.png";

const Logo = styled(Image)`
  height: auto;
  width: 140px;
  margin-left: 1rem;

  @media (max-width: ${theme.device.medium}) {
    margin-left: 1rem;
  }
`;

const LogoText = styled.span`
  display: none;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const MenuContainer = styled.div`
  position: relative;
  background-color: ${theme.color.white};

  & .headroom {
    border-color: ${theme.color.white};
    background-color: ${theme.color.white};
    &.headroom--unfixed {
      border-color: transparent;
    }
    &.headroom--unpinned {
      background-color: ${theme.color.white};
    }
    & .background--white {
      background-color: ${theme.color.white};
    }
  }

  ${(props) =>
    props.transparent &&
    `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .headroom {
      background-color: transparent;
      nav a {
        color: rgb(243, 238, 223);
        border-bottom-color: rgb(243, 238, 223);
      }

    }
  `}

  ${(props) =>
    props.border &&
    `
    & .headroom {
      border-color: ${theme.color.black};
      &.headroom--unfixed {
        border-color: ${theme.color.black};
      }
      &.headroom--unpinned {
        border-color: ${theme.color.white};
      }
    }
  `}
`;

const MenuBar = styled.header`
  height: 100%;
`;

const MenuBarContent = styled.nav`
  max-width: ${theme.layout.width};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const MobileMenu = styled.div`
  align-items: center;

  @media (min-width: ${theme.device.medium}) {
    display: none;
  }

  @media (max-width: ${theme.device.medium}) {
    display: flex;
  }
`;

const ButtonList = styled.ul`
  display: flex;
  list-style-type: none;
  position: relative;

  li:first-child {
    margin-right: 1rem;
  }

  @media (max-width: ${theme.device.medium}) {
    order: 3;
    flex-basis: 100%;
    justify-content: center;
    margin-top: 2rem;
  }
`;

const HoursText = styled.div`
  display: flex;
  flex-direction: row;
  /* order: 3; */
  /* order: 3; */

  @media (max-width: ${theme.device.medium}) {
    order: 2;
    flex-basis: 100%;
    justify-content: center;
    margin-top: 2rem;

    p {
      max-width: 25ch;
      font-size: 1.2rem;
      line-height: 1.6;
      margin: 2rem 0 1rem 0;
    }
  }
`;

const LinkButton = styled.li`
  a {
    color: inherit;
    font-size: ${theme.font.display6};
    font-weight: bold;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border-bottom: none;
    border-bottom-color: none;
    position: relative;
    text-align: inherit;

    border: 1px solid #fff;
    border-radius: 6px;
    padding: 0.45rem 0.9rem 0.5rem 1rem;
  }
`;

class MenuNavBar extends React.Component {
  render() {
    const menu = this.props.data;

    return (
      <MenuContainer {...this.props}>
        <ScrollVisibilityHeader disableInlineStyles={true}>
          <MenuBar>
            <MenuBarContent>
              <LogoContainer>
                <Link to={"/"}>
                  <Logo src={logo} width="868" height="338" alt="Long Play" />
                  <LogoText>Long Play</LogoText>
                </Link>
              </LogoContainer>
              {/* <NavMenuList data={menu.mainMenu} /> */}
              <HoursText className="js-hide-on-scroll">
                <Typography display6 centre white noMargin>
                  <br />
                  Mon 5pm-10pm
                  <br />
                  Tues Closed
                  <br />
                  Wed 5pm-10pm
                  <br />
                  Thu 5pm-12am
                  <br />
                  Fri 4pm-1am
                  <br />
                  Sat 3pm-1am
                  <br />
                  Sun 4pm-10pm
                </Typography>
              </HoursText>
              {/* <ButtonList className="js-hide-on-scroll"> */}
              {/* <LinkButton>
                  <a href="https://www.opentable.com.au/restref/client/?rid=143984">
                    Make Booking
                  </a>
                </LinkButton> */}
              {/* <LinkButton>
                  <Link to={"/bar-menu"}>Bar menu</Link>
                </LinkButton>
                <LinkButton>
                  <Link to={"/wine-list"}>Wine list</Link>
                </LinkButton>
                <LinkButton>
                  <Link to={"/wine-list"}>Cocktails</Link>
                </LinkButton> */}
              {/* </ButtonList> */}
              <MobileMenu>
                <MenuInModal data={menu.mainMenu} />
              </MobileMenu>
              {/* <HoursText className='js-hide-on-scroll'>
                <Typography
                  display4
                  centre
                  white
                  noMargin
                >
                  Su-We 6-11
                <br />
                  Th-Sa 6-1am
                </Typography>
              </HoursText> */}
            </MenuBarContent>
          </MenuBar>
        </ScrollVisibilityHeader>
      </MenuContainer>
    );
  }
}

export default MenuNavBar;
