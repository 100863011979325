import React from "react";
import { Link } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link'
import styled from "styled-components";

import theme from "theme";
import Layout from "Layout";
import List, { Item } from "List";
import Typography from "Typography";
import Image from "Image";

import logo from "static/images/logo.png";

const Footer = styled(Layout)`
  display: flex;
  background-color: ${theme.color.grey.lightest};
  background-color: rgba(230, 231, 239, 0.3);
  background-color: #f8f8fa; // Temp hack
  padding: 2rem 0 4rem 0;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const FooterList = styled(List)`
  li {
    line-height: 1.8rem;
  }

  @media (max-width: ${theme.device.small}) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Logo = styled(Image)`
  height: auto;
  width: 180px;
  margin-top: 10px;
`;

const LogoText = styled.span`
  display: none;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 30%;
  justify-content: center;

  @media (max-width: ${theme.device.medium}) {
    margin-bottom: 4rem;
    align-items: center;
  }

  @media (max-width: ${theme.device.small}) {
    flex-basis: 100%;
    margin: auto;
    text-align: center;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 70%;
  justify-content: space-between;

  @media (max-width: ${theme.device.small}) {
    flex-basis: 100%;
    flex-direction: column;
    margin: auto;
    text-align: center;
  }
`;

export default class extends React.Component {
  render() {
    return (
      <Footer container>
        <LogoContainer>
          <Link to={"/"}>
            <Logo src={logo} width="868" height="338" alt="Long Play" />
            <LogoText>Long Play</LogoText>
          </Link>
        </LogoContainer>
        <MenuContainer>
          <FooterList block>
            <Item>
              <Typography bold noMargin>
                Hours
              </Typography>
            </Item>
            <Item>Mon 5pm - 10pm</Item>
            <Item>Tues Closed</Item>
            <Item>Wed 5pm - 10pm</Item>
            <Item>Thu 5pm - 12am</Item>
            <Item>Fri 4pm - 1am</Item>
            <Item>Sat 3pm - 1am</Item>
            <Item>Sun 4pm - 10pm</Item>
          </FooterList>
          <FooterList block>
            <Item>
              <Typography bold noMargin>
                Contact
              </Typography>
            </Item>
            <Item>
              <a href="tel:+61 421 157 967">0421 157 967</a>
            </Item>
            <Item>
              <a href="mailto:info@longplay.net.au">info@longplay.net.au</a>
            </Item>
          </FooterList>
          <FooterList block>
            <Item>
              <Typography bold noMargin>
                Location
              </Typography>
            </Item>
            <Item>
              <a href="https://goo.gl/maps/BJHoW8La4scihCbs9">
                318 St Georges Rd
              </a>
            </Item>
            <Item>
              <a href="https://goo.gl/maps/BJHoW8La4scihCbs9">
                Fitzroy North VIC3068
              </a>
            </Item>
          </FooterList>
        </MenuContainer>
      </Footer>
    );
  }
}
