import { fetchUrl, api } from "config";
import cachedFetch from "Fetch";
import isLoggedIn from "isLoggedIn";

// If logged in or running development server get JSON direct from rest endpoint
// Otherwise use cached JSON file
// If JSON file doesn't exist revert to endpoint

export default (slug, type) => {
  let endpoint, filename, postOrder;
  postOrder = "?orderby=menu_order&order=asc&per_page=999";

  // Global data - single JSON but 3x REST endpoints
  if (type === "Global") {
    filename = "global.json";
    if (slug === "home" || slug === "front" || slug === null)
      endpoint = "page/home";
  }

  // Individual JSON files / REST endpoints
  // default case - use error/404 or page or null object?
  // eslint-disable-next-line
  switch (type) {
    case "Page":
      endpoint = "page/" + slug;
      if (slug === "home" || slug === "front") filename = "front-page.json";
      else filename = "page/" + slug + "-page.json";
      break;
    case "Post":
      endpoint = "post/" + slug;
      filename = "post/" + slug + ".json";
      break;
    case "Portfolio":
      endpoint = "page/" + slug;
      filename = "page/" + slug + "-page.json";
      break;
    case "WhoWeAre":
      endpoint = "page/" + slug;
      filename = "page/" + slug + "-page.json";
      break;
    case "Contact":
      endpoint = "page/" + slug;
      filename = "page/" + slug + "-page.json";
      break;
    case "CaseStudy":
      endpoint = "case-study/" + slug;
      filename = "case-study/" + slug + ".json";
      break;
    case "Campaign":
      endpoint = "campaign/" + slug;
      filename = "campaign/" + slug + ".json";
      break;
    case "AllCaseStudyPosts":
      endpoint = slug + postOrder;
      filename = "case-study-archive.json";
      break;
    case "AllPeoplePosts":
      endpoint = slug + postOrder;
      filename = "people-archive.json";
      break;
    case "AllBlogPosts":
      endpoint = "posts" + postOrder;
      filename = "post-archive.json";
      break;
  }

  // Check for JSON file, if 404 then use REST endpoint
  // TODO needs some sort of abort controller as currently missing JSON files
  // are outputting to console with 404
  // Or make promise cancellable
  // https://github.com/facebook/react/issues/5465#issuecomment-339544623
  const fetchResponse = () => {
    return cachedFetch(fetchUrl + filename)
      .then((response) => {
        if (response.status.ok === "undefined" && response.status !== 200) {
          return cachedFetch(api + endpoint);
        } else {
          return response;
        }
      })
      .catch((response) => {
        // If JSON file is not present
        Promise.reject();
        return cachedFetch(api + endpoint);
      });
  };

  // If running the CRA nodejs development server or if logged in,
  // then view posts from REST API endpoints
  //    - if JSON files do not exist / yet to be written
  //    - avoid any caching issues with JSON
  if (process.env.NODE_ENV === "development" || isLoggedIn) {
    if (type === "Global") {
      // Need all the endpoints that are used to form global.json
      // Could get the menus programmatically from the REST API
      // Don't forget to update the array keys in the nested Promise return
      const mainMenu = cachedFetch(api + "menus/main-menu").then((response) =>
        response.json()
      );
      const home = cachedFetch(api + endpoint).then((response) =>
        response.json()
      );
      const options = cachedFetch(api + "options/acf").then((response) =>
        response.json()
      );

      return Promise.all([mainMenu, home, options]).then((json) => {
        const menus = {
          mainMenu: json[0],
        };
        return { menus: menus, home: json[1], options: json[2] };
      });
    } else {
      return cachedFetch(api + endpoint).then((response) => response.json());
    }
  } else {
    return fetchResponse().then((response) => response.json());
  }
};
