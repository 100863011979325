import React from "react";

import SiteTitle from "SiteTitle";
import Menu from "Menu";
import Header from "Header";
import Builder from "Builder";
import Footer from "Footer";

import getFetchJson from "Response";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: [],
      dataGlobal: []
    };
  }

  getJson = slug => {
    const pageData = getFetchJson(slug, "Page");
    const globalData = getFetchJson(null, "Global");

    return Promise.all([pageData, globalData]).then(json => {
      this.setState({
        data: json[0],
        dataGlobal: json[1],
        isLoaded: true
      });
    });
  };

  componentDidMount() {
    const slug = this.props.match.params.id;
    this.getJson(slug);
  }

  render() {
    const { error, isLoaded, data, dataGlobal } = this.state;
    let hasHeader = false;
    // console.log(data);

    // Quick hack to disable header?
    // if (data.acf.header_show !== undefined) return null;
    // else {
    //   hasHeader = true;
    // }

    if (error) {
      return <div>Error: {error.message}Error</div>;
    } else if (!isLoaded) {
      return null;
    } else {
      return (
        <main>
          <SiteTitle>Long Play</SiteTitle>
          <Menu data={dataGlobal.menus} />
          {hasHeader && <Header data={data} bgHeight={"180px"} />}
          <Builder data={data} />
          <Footer />
        </main>
      );
    }
  }
}

export default Page;
