import React from 'react';
import styled from 'styled-components';

import theme from 'theme';

// Default <Typography/> with no props is
// <p> in theme black at 16px using regular weight 400
const Text = styled.p`

  font-size: 1rem;
  font-size: ${props => ( props.display1 || props.size52 ) && '3.25rem'};
  font-size: ${props => ( props.display2 || props.size40 ) && '2.5rem'};
  font-size: ${props => ( props.display3 || props.size28 ) && '1.75rem'};
  font-size: ${props => ( props.display4 || props.size24 ) && '1.5rem'};
  font-size: ${props => ( props.display5 || props.size18 ) && '1.125rem'};
  font-size: ${props => ( props.display6 || props.size12 ) && '0.75rem'};

  @media (max-width: ${theme.device.medium}) {
    font-size: ${props => ( props.display1 || props.size52 ) && '3.25rem'};
    font-size: ${props => ( props.display2 || props.size40 ) && '1.75rem'};
    font-size: ${props => ( props.display3 || props.size28 ) && '1.5rem'};
    font-size: ${props => ( props.display4 || props.size24 ) && '1.25rem'};
    font-size: ${props => ( props.display5 || props.size18 ) && '1.125rem'};
    font-size: ${props => ( props.display6 || props.size12 ) && '0.75rem'};
  }

  @media (min-width: ${theme.device.medium}) {
    font-size: 1.125rem;
    font-size: ${props => ( props.display2 || props.size40 ) && '2.8125rem'};
    font-size: ${props => ( props.display3 || props.size28 ) && '2rem'};

    line-height: 1.8rem;
    line-height: ${props => ( props.display2 || props.size38 ) && '3.375rem'};
  }

  @media (min-width: ${theme.device.large}) {
    ${'' /* font-size: 1.125rem; */}
    font-size: 1.25rem;
    font-size: ${props => ( props.display2 || props.size40 ) && '2.8125rem'};
    ${'' /* font-size: ${props => ( props.display3 || props.size28 ) && '2rem'}; */}
    font-size: ${props => ( props.display3 || props.size28 ) && '2.5rem'};
    font-size: ${props => ( props.display6 || props.size12 ) && '1rem'};

    ${'' /* line-height: 1.8rem; */}
    line-height: 2rem;
    line-height: ${props => ( props.display2 || props.size40 ) && '3.375rem'};
    line-height: ${props => ( props.display3 || props.size28 ) && '3rem'};
  }

  font-weight: normal;
  font-weight: ${props => props.normal && 'normal'};
  font-weight: ${props => props.bold && 'bold'};

  letter-spacing: ${props => props.spaced && '1px'};
  text-transform: ${props => props.uppercase && 'uppercase'};
  text-transform: ${props => props.capitalize && 'capitalize'};

  text-align: ${props => ( props.centre || props.center ) && 'center'};

  color: ${ theme.color.black };
  color: ${ props => ( props.colorWhite || props.white ) && theme.color.white };
  color: ${ props => ( props.colorMint || props.colorMintPrimary ) && theme.color.mint.primary };
  color: ${ props => props.colorMintMedium && theme.color.mint.medium };
  color: ${ props => props.colorMintDark && theme.color.mint.dark };
  color: ${ props => props.colorGreyLight && theme.color.grey.light };

  color: ${ props => props.color && props.color };

  line-height: 1.6rem;
  line-height: ${props => ( props.display1 || props.size54 ) && '4.7rem'};
  line-height: ${props => ( props.display2 || props.size38 ) && '3rem'};
  line-height: ${props => ( props.display3 || props.size30 ) && '2.8rem'};
  line-height: ${props => ( props.display4 || props.size24 ) && '2rem'};
  line-height: ${props => ( props.display5 || props.size18 ) && '1.6rem'};
  line-height: ${props => ( props.display6 || props.size18 ) && '1rem'};
  line-height: ${props => ( props.decorative160 || props.size160 ) && '10rem'};
  line-height: ${props => props.lineHeight && props.lineHeight};

  margin: ${props => ( props.margin === false || props.noMargin ) && '0'};

  a {
    font-weight: normal;
    font-size: inherit;
    ${'' /* color: ${theme.color.mint.primary}; */}
    color: ${theme.color.black};
    ${'' /* border-bottom: 2px solid ${theme.color.mint.primary}; */}
    border-bottom: 2px solid ${theme.color.black};

    &:hover {
      border-bottom: 2px solid ${theme.color.mint.primary};
      color: ${theme.color.mint.primary};
      padding-bottom: 0.1rem;
    }
  }
`;

// Need to find a better way to define font-family in global.css
const P = Text;
const Div = Text.withComponent('div');
const H1 = Text.withComponent('h1');
const H2 = Text.withComponent('h2');
const H3 = Text.withComponent('h3');
const H4 = Text.withComponent('h4');
const H5 = Text.withComponent('H5');
const H6 = Text.withComponent('h6');

// Gotta be a better way
class Typography extends React.Component {

  render() {

    if (this.props.heading1) {

      return (

        <H1 {...this.props}>
          {this.props.children}
        </H1>

      );

    } else if (this.props.heading2) {

      return (

        <H2 {...this.props}>
          {this.props.children}
        </H2>

      );

    } else if (this.props.heading2) {

      return (

        <H3 {...this.props}>
          {this.props.children}
        </H3>

      );

    } else if (this.props.heading2) {

      return (

        <H4 {...this.props}>
          {this.props.children}
        </H4>

      );

    } else if (this.props.heading2) {

      return (

        <H5 {...this.props}>
          {this.props.children}
        </H5>

      );

    } else if (this.props.heading2) {

      return (

        <H6 {...this.props}>
          {this.props.children}
        </H6>

      );

    } else if (this.props.paragraph) {

      return (

        <P {...this.props}>
          {this.props.children}
        </P>

      );

    } else if (this.props.div) {

      return (

        <Div {...this.props}>
          {this.props.children}
        </Div>

      );

    } else {

      return (

        <P {...this.props}>
          {this.props.children}
        </P>

      );

    }

  }

}

export default Typography;
