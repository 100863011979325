import React from 'react';

import getFetchJson from 'Response';

const getData = (slug, type = null) => (Comp) =>
  class getData extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        data: [],
        dataGlobal: [],
        dataAllCaseStudies: [],
        dataAllPeople: [],
        dataAllPosts: [],
      };
    }

    componentDidMount() {

      // Send Fetch requests at same time, get response JSON
      // When all data retrieved, combine promises then update state,
      // disadvantage is that all the below data is retrieved for any view
      // using this HOC but these will get cached into SessionStorage
      // ie Portfolio page doesn't need the People/Posts archives data
      //    - does only loading required JSON files (in prod) load quicker
      //    - is one payload JSON faster than three JSON files for each archive
      // Alternative is to send Fetch requests using async/await (see DataAsync.js),
      // but disadvantage is that they run sequentially not simultaneously
      // after each await is finished
      const data = getFetchJson( slug, type )
      const globalData = getFetchJson( null, 'Global' )
      const caseStudyData = getFetchJson('case-study', 'AllCaseStudyPosts')
      const peopleData = getFetchJson('people', 'AllPeoplePosts')
      const postsData = getFetchJson('post', 'AllBlogPosts')

      Promise.all([data,globalData,caseStudyData,peopleData,postsData])
        .then((json) => {
          this.setState({
            data: json[0],
            dataGlobal: json[1],
            dataAllCaseStudies: json[2],
            dataAllPeople: json[3],
            dataAllPosts: json[4],
            isLoaded: true,
          })
        })

    }

    render() {

      const {
        error,
        isLoaded,
      } = this.state;

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return null;
      } else {

        return (

          <Comp { ...this.props } { ...this.state } />

        );

      }

    }

  }

export default getData;
