import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import theme from 'theme'
import Layout from 'Layout'
import Card from 'Card'
// import Image from 'Image'
import Button from 'Button'
import List, { Item } from 'List'
import Typography from 'Typography'

const ColumnsLayout = styled(Layout)`
  padding-top: 4rem;
`

const ColumnsListCard = styled(Card)``

const ColumnsList = styled(List)`
  display: flex;
  justify-content: space-between;
  text-align: center;

  @media (max-width: ${theme.device.medium}) {
    flex-direction: column;
  }

  img {
    width: auto;
    height: 140px;
  }

  ${ColumnsListCard} {
    padding: 2rem;

    p {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      margin-top: 2rem;
    }
  }
`

function getUrlPath (string) {

  const url = new URL(string);
  return url.pathname;

}

export default class extends React.Component {

  render() {

    const content = this.props.data.column

    return (

      <ColumnsLayout container>
        <ColumnsList>
          {content.map((item, i) =>
            <Item key={i}>
              <ColumnsListCard>
                <img
                  src={item.image.url}
                  alt={item.image.alt}
                  title={item.image.title}
                  width="100"
                />
                <Typography
                  bold
                  heading3
                  display3
                >
                  {item.heading}
                </Typography>
                <Typography
                  noMargin
                >
                  {item.text}
                </Typography>
                <Link to={getUrlPath(item.link.url)}>
                  <Button>
                    More
                  </Button>
                </Link>
              </ColumnsListCard>
            </Item>
          )}
        </ColumnsList>
      </ColumnsLayout>
    );

  }

}
