import React from "react";

const Menu = props => (
  <svg width={22} height={18} viewBox="0 0 22 18" {...props}>
    <title>Main Navigation Menu</title>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M20.612 2H22V0H0v2h1.388zM20.612 10H22V8H0v2h1.388zM20.612 18H22v-2H0v2h1.388z" />
    </g>
  </svg>
);

export default Menu;
