import React from 'react';
import styled from 'styled-components';

// Custom project components
import Typography from 'Typography';

const Title = styled(Typography)`
  display: none;
`;

class SiteTitle extends React.Component {

  render() {

    return (

      <Title heading1>
        {this.props.children}
      </Title>

    );

  }

}

export default SiteTitle;
